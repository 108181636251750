import React, { useEffect } from "react";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import Styles from "./form.module.css";

// Import dos Componentes
import Background from "../background/backCadastro/backCadastro";

export default function Form(){
  const navigate = useNavigate();
    
  useEffect(() =>{
      logado();
  })

  const login = async(event)=>{
      event.preventDefault();
      const usuario = document.getElementById("email").value;
      const senha = document.getElementById("senha").value;

      try {
          const response = await axios.post('/login', { usuario, senha });
          if(response.data.nome){
              navigate('/home');
          }
          
      } catch (error) {
          console.log(error);
          if (error.response) {
              alert(error.response.data.message);
          } else {
              console.log('Erro ao realizar login.');
          }
      }
  }

  const logado = async()=>{
      try {
          await axios.post('/login');
          navigate('/home')
      } catch (error) {
          console.log(error);
          if (error.response) {
              console.log(`Erro: ${error.response.data.message}`);
          } else {
              alert('Erro ao realizar login.');
          }
      }
  }

  return(
    <div>
        <Background />
        <div className={Styles.container_formlogin}>
          <div className={Styles.borda}>
            <form className={Styles.form} autoComplete="off">
              <div className={Styles.textcenter}>
                <h1>Login</h1>
              </div>
              <div className={Styles.esquerda}>
                <label className={Styles.label}><b>Usuário</b></label><br />
                <input type="email" id="email" placeholder="Coloque seu usuário aqui"
                 required className={Styles.input}/><br />
              </div>
              <div className={Styles.esquerda}>
                <label className={Styles.label}><b>Senha</b></label><br />
                <input type="password" id="senha" placeholder="Coloque sua senha aqui" 
                required className={Styles.input}/><br />
              </div>
              <div>
                <button type="submit" className={Styles.btn} onClick={login}>
                  <h1>Entrar</h1>
                </button>
              </div>
            </form>
          </div>
        </div>
    </div>

  )
}